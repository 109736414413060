<template>
  <div class="setpassword content_getresponse !bg-[#F1F2F7]">
    <div class="setpassowrd_inner container !rounded-[16px] !max-w-[30rem] ">

      <div class=" flex flex-col justify-center items-center gap-y-3 ">
        <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-bold leading-5">Connect SendPulse</p>
        <p class=" text-[14px] font-normal font-poppins text-[#757A8A] text-center">Enter Your Access Token below to
          connect your SendPulse account.</p>
      </div>

      <transition name="slide">
        <div v-if="!show_lists" class="px-8 pb-6 pt-10 flex flex-col justify-center items-center gap-y-3 ">
          <FloatingLabelInput
            id="name"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="SendPulse API Key"
            placeholder="API Key"
            v-model="api"
          >
          </FloatingLabelInput>
          <FloatingLabelInput
            id="url"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="SendPulse API Secret Key"
            placeholder="API Secret Key"
            v-model="apiSecret"
          >
          </FloatingLabelInput>
          <div class=" mt-3  flex gap-x-3 justify-between items-center">
            <Button
              id="login-button"
              type="button"
              class="!border-[1.5px] !border-[#3C4549] !py-[1.142rem]  !text-[14px] !font-semibold !rounded-[0.571rem] hover:bg-[#F2F3F8]"
              buttonClass="btn-lg"
              @click="$router.push({name: 'integrations'})"
            >
              <template v-slot:label>Close</template>
            </Button>
            <Button
              id="API"
              type="button"
              class="py-[1.14rem] !text-[14px] !font-semibold !text-white !rounded-[8px] bg-[#2560D7] hover:bg-[#1d4ed8]"
              buttonClass="btn-lg"
              :disabled="disable_connect"
              @click.prevent="setSendPulse()">
              <template v-slot:label>Connect</template>
            </Button>
          </div>
        </div>
      </transition>

      <transition name="slide">
        <form v-if="show_lists" class="basic_form clear !py-3" action="javascript:;">

          <ul v-if="lists" class="check_list">
            <li v-for="list in lists" class="checkbox">
              <input v-model="selected_lists" type="checkbox" :value="list" :id="list.id">
              <label :for="list.id">{{ list.name }}</label>
            </li>
          </ul>
          <div class="col-md-12 text-center btn_sec">
            <Button
              id="Save"
              type="button"
              class="py-[1.14rem] !text-[14px] !font-semibold !text-white !rounded-[8px] bg-[#2560D7] hover:bg-[#1d4ed8]"
              buttonClass="btn-lg"
              @click.prevent="saveSendPulseAccount()">
              <template v-slot:label>Save</template>
            </Button>
          </div>
        </form>

      </transition>

    </div>
  </div>
</template>
<script>
import {
  SendPulseConnectURL,
  sendPulseSaveAccountURL
} from '@/config/config.js'
import {mapGetters} from 'vuex'
import http from '@/mixins/http-lib'
import vueCookie from 'vue-cookie'
import {INTEGRATION_COOKIE} from "../../../constants";

export default (
  {
    name: 'sendPulse',
    components: {
      FloatingLabelInput: () => import('@/ui/ui-kit/v2/FloatingLabelInput.vue'),
      Button: () => import('@/ui/ui-kit/v2/Button.vue')
    },
    data() {
      return {
        api: null,
        apiSecret: null,
        lists: [],
        show_lists: false,
        selected_lists: [],
        disable_connect: false,

        reconnect: false,
        account_id: '',
        expire: null,
        integration_id: null

      }
    },
    async created() {
      await this.getUserProfile()
      await this.fetchWhitelabelAppSettings()
    },
    computed: {
      ...mapGetters(['getProfile', 'getWorkspace'])
    },
    methods: {

      setSendPulse() {
        if (this.api == '') {
          this.alertMessage('Please enter your SendPulse API Key', 'error')
          return
        }
        if ((this.apiSecret.trim()).length == 0) {
          this.alertMessage('Please enter your SendPulse API Secret Key', 'error')
          return
        }
        this.disable_connect = true

        let localStorageWorkspace = JSON.parse(localStorage.getItem('workspace'))
        let brand_id = this.getWorkspace && this.getWorkspace._id ? this.getWorkspace._id : localStorageWorkspace._id

        const integration = this.getIntegrationPayload()
        http.post(SendPulseConnectURL, {
          api: this.api,
          apiSecret: this.apiSecret,
          ...integration,
          brand_id: brand_id
        }).then(
          response => {
            if (response.data.tokenError && response.data.tokenError == true) {
              this.alertMessage(response.data.message, 'error')
              this.disable_connect = false
            } else {
              if (response.data.status) {
                this.lists = response.data.lists
                this.show_lists = true
                this.reconnect = response.data.reconnect
                this.account_id = response.data.account_id
                this.name = response.data.name
                this.expire = response.data.expire
                this.integration_id = response.data.integration_id
              } else {
                vueCookie.set(INTEGRATION_COOKIE, JSON.stringify(integration));
                this.alertMessage(response.data.message, 'error')
              }
              this.disable_connect = false
            }
          },
          response => {
            this.disable_connect = false
            this.alertMessage('Unknown error occurred, support has been notified.', 'error')
          })
      },

      saveSendPulseAccount() {
        if (this.selected_lists.length == 0) {
          this.alertMessage('Please select atleast one group.', 'error')
          return
        }

        let localStorageWorkspace = JSON.parse(localStorage.getItem('workspace'))
        let brand_id = this.getWorkspace && this.getWorkspace._id ? this.getWorkspace._id : localStorageWorkspace._id

        http.post(sendPulseSaveAccountURL, {
          api: this.api,
          apiSecret: this.apiSecret,
          selected_lists: this.selected_lists,
          reconnect: this.reconnect,
          integration_id: this.integration_id,
          expire: this.expire,
          brand_id: brand_id
        }).then(
          response => {
            if (response.data.tokenError && response.data.tokenError == true) {
              this.alertMessage(response.data.message, 'error')
            } else {
              if (response.data.status) {
                this.alertMessage(response.data.message, 'success')
                this.getUserProfile()
                this.$router.push({
                  name: 'integrations'
                })
              } else {
                this.alertMessage(response.data.message, 'error')
              }
            }
          },
          response => {
            this.alertMessage('Unknown error occurred, administrator has been notified.', 'error')
          })
      }
    }
  }
)
</script>
